<template>
  <div style="padding: 12px">
    <a-date-picker
      mode="year"
      placeholder="年份"
      @panelChange="panelChange"
      :value="year"
      format="YYYY"
      :open="open"
      @openChange="openChange"
      style="width: 120px"
      :allowClear="false"
    />

    <Padding size="large" />

    <a-table bordered :dataSource="list" :pagination="false" :loading="loading" rowKey="name">
      <a-table-column title="节假日" data-index="name" align="center"></a-table-column>

      <a-table-column title="假期范围" align="center">
        <template slot-scope="text">
          <div v-if="text.startHoliday">
            <span>{{ text.startHoliday }}</span>
            <span>~</span>
            <span>{{ text.endHoliday }}</span>
          </div>
        </template>
      </a-table-column>

      <a-table-column title="调休日期" align="center">
        <template slot-scope="text">
          <div v-if="Array.isArray(text.workday)">
            <span v-for="(item, index) in text.workday" :key="item">
              {{ item }}
              <span v-if="index !== text.workday.length - 1">,</span>
            </span>
          </div>
        </template>
      </a-table-column>

      <a-table-column title="具体假期（用于计算产假）" align="center">
        <template slot-scope="text">
          <div v-if="Array.isArray(text.realHoliday)">
            <span v-for="(item, index) in text.realHoliday" :key="item">
              {{ item }}
              <span v-if="index !== text.realHoliday.length - 1">，</span>
            </span>
          </div>
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center" v-if="!disabled">
        <template slot-scope="text, row, index">
          <a href="#" @click.prevent="edit(text, index)">配置</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="center" style="margin: 24px 0" v-if="!disabled">
      <a-button type="primary" @click="save" :loading="saveLoading">保存</a-button>
    </div>

    <div style="margin-top: 16px">
      请每年年末根据国家政策(
      <a href="https://www.gov.cn/" target="_blank">政府官网</a>)配置明年的法定节假日，要不然用户在请假时，程序无法自动读取该年的法定节假日，从而导致用户申请的假期长度可能与实际不符。
    </div>

    <div style="padding-top: 8px">比如用户在2023年劳动节前后请两天年假，用户选择时间范围包括了劳动节5天。</div>
    <div style="padding-top: 8px">如果本程序已配置了2023年法定节假日，本程序会计算得出用户本次年假请假长度为2天。</div>
    <div style="padding-top: 8px">如果本程序没配置2023年法定节假日，则用户本次年假请假长度为7天，导致用户剩余年假变少。</div>
    <div style="padding-top: 8px; color: red">建议在非工作时间配置</div>

    <a-modal
      :title="editingText.name ? editingText.name : ''"
      :visible="visible"
      :footer="null"
      @cancel="cancel"
    >
      <div class="label">假期范围</div>
      <a-range-picker v-model="selectedHoliday" :allowClear="false" />

      <Padding size="large" />

      <div class="label">调休日期</div>

      <div v-for="(item, index) in selectedWorkday" :key="'work' + index">
        <a-space>
          <a-date-picker v-model="item.date" :allowClear="true" />

          <a-button
            size="small"
            type="primary"
            shape="circle"
            icon="plus"
            style="margin-left: 8px"
            @click="add"
          ></a-button>
          <a-button
            size="small"
            type="danger"
            shape="circle"
            icon="minus"
            style="margin-left: 8px"
            @click="remove(index)"
            v-if="index !== 0"
          ></a-button>
        </a-space>
        <Padding />
      </div>

      <Padding size="large" />

      <div class="label">具体假期（用于计算产假）</div>

      <div v-for="(item, index) in selectedRealHoliday" :key="'real' + index">
        <a-space>
          <a-date-picker v-model="item.date" :allowClear="true" />

          <a-button
            size="small"
            type="primary"
            shape="circle"
            icon="plus"
            style="margin-left: 8px"
            @click="addReal"
          ></a-button>
          <a-button
            size="small"
            type="danger"
            shape="circle"
            icon="minus"
            style="margin-left: 8px"
            @click="removeReal(index)"
            v-if="index !== 0"
          ></a-button>
        </a-space>
        <Padding />
      </div>

      <Padding size="large" />

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="addItem">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/office-service/att/holiday/query/" + params.year
  });
}

function add(data) {
  return request({
    url: "/office-service/att/holiday/add",
    method: "post",
    data
  });
}

export default {
  data() {
    return {
      year: moment(),
      open: false,

      loading: false,
      list: [],

      disabled: false,

      editingText: {},
      editingIndex: -1,
      visible: false,

      selectedHoliday: [],
      selectedWorkday: [
        {
          date: null
        }
      ],

      selectedRealHoliday: [
        {
          date: null
        }
      ],
      saveLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    holiday() {
      return this.findDataDict("holiday");
    }
  },

  watch: {
    year() {
      this.getList();
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    panelChange(value) {
      this.year = value;
      this.open = false;
    },
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.list = [];
      this.loading = true;
      fetchList({
        year: this.year.format("YYYY")
      })
        .then(res => {
          try {
            const list = JSON.parse(res.data);
            if (list.length > 0) {
              // this.disabled = true;
              this.disabled = false;
              this.list = list;
            } else {
              this.disabled = false;
              this.list = this.holiday.map(item => {
                return {
                  name: item.name,
                  startHoliday: "",
                  endHoliday: "",
                  workday: [],
                  realHoliday: []
                };
              });
            }
          } catch (error) {
            alert("数据格式错误");
          }
        })
        .catch(err => {
          console.log("err", err);

          this.list = this.holiday.map(item => {
            return {
              name: item.name,
              startHoliday: "",
              endHoliday: "",
              workday: [],
              realHoliday: []
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    edit(text, index) {
      this.editingText = text;
      this.editingIndex = index;
      this.visible = true;

      if (text.startHoliday && text.endHoliday) {
        this.selectedHoliday = [
          moment(text.startHoliday),
          moment(text.endHoliday)
        ];
      }
      this.selectedWorkday =
        Array.isArray(text.workday) && text.workday.length > 0
          ? text.workday.map(item => ({ date: moment(item) }))
          : [
              {
                date: null
              }
            ];

      this.selectedRealHoliday =
        Array.isArray(text.realHoliday) && text.realHoliday.length > 0
          ? text.realHoliday.map(item => ({ date: moment(item) }))
          : [
              {
                date: null
              }
            ];
    },

    cancel() {
      this.visible = false;
      this.editingText = {};
      this.editingIndex = -1;

      this.selectedHoliday = [];
      this.selectedWorkday = [
        {
          date: null
        }
      ];
      this.selectedRealHoliday = [
        {
          date: null
        }
      ];
    },

    add() {
      this.selectedWorkday.push({
        date: null
      });
    },
    remove(index) {
      this.selectedWorkday.splice(index, 1);
    },

    addReal() {
      this.selectedRealHoliday.push({
        date: null
      });
    },
    removeReal(index) {
      this.selectedRealHoliday.splice(index, 1);
    },

    addItem() {
      console.log(
        this.selectedHoliday,
        this.selectedWorkday,
        this.selectedRealHoliday
      );

      if (this.selectedHoliday.length !== 2) {
        this.$message.error("请选择假期范围");
        return;
      }
      if (this.selectedRealHoliday.length === 0) {
        this.$message.error("请选择具体假期");
        return;
      }

      if (this.selectedWorkday.length > 1) {
        let flag = false;

        this.selectedWorkday.forEach(item => {
          if (!item.date) {
            flag = true;
          }
        });

        if (flag) {
          this.$message.error("请选择调休日期");
          return;
        }
      }

      if (this.selectedRealHoliday.length > 0) {
        let flag = false;

        this.selectedRealHoliday.forEach(item => {
          if (!item.date) {
            flag = true;
          }
        });

        if (flag) {
          this.$message.error("请选择具体假期");
          return;
        }
      }

      const workday = [];
      this.selectedWorkday.forEach(item => {
        if (item.date) {
          workday.push(item.date.format("YYYY-MM-DD"));
        }
      });

      const realHoliday = [];
      this.selectedRealHoliday.forEach(item => {
        if (item.date) {
          realHoliday.push(item.date.format("YYYY-MM-DD"));
        }
      });

      this.list.splice(this.editingIndex, 1, {
        name: this.editingText.name,

        startHoliday: this.selectedHoliday[0].format("YYYY-MM-DD"),
        endHoliday: this.selectedHoliday[1].format("YYYY-MM-DD"),
        workday,
        realHoliday
      });

      this.cancel();
    },

    save() {
      let flag = false;

      this.list.forEach(item => {
        if (!item.startHoliday) {
          flag = true;
        }
      });

      if (flag) {
        this.$message.error("请先配置假期");
        return;
      }

      const that = this;
      this.$confirm({
        title: "警告",
        content: "请确认后提交",
        okType: "danger",
        okText: "我已知晓",
        onOk() {
          that.saveLoading = true;
          add({
            year: that.year.format("YYYY"),
            name: "节假日配置",
            data: JSON.stringify(that.list)
          })
            .then(() => {
              that.getList();
            })
            .finally(() => {
              that.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
}
</style>