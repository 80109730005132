var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"16px"}},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"打卡日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'date',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":"","placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"打卡类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'checkType',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'checkType',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l(([
                  {
                    name: '上班',
                    value: 'OnDuty',
                  },
                  {
                    name: '下班',
                    value: 'OffDuty',
                  },
                ]),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.value}},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"员工"}},[_c('EmployeeSelector',{attrs:{"single":true},on:{"change":_vm.onSelectEmployee}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.selected.userId ? _vm.selected.name : '选择'))])],1)],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("确认新增")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }