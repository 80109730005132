<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs :tabBarStyle="{
          margin: '0',
        }" v-model="active">
        <a-tab-pane
          v-for="item in [
               {
              name: '打卡时间配置',
              value: 'clock',
            },
            {
              name: '法定节假日配置',
              value: 'holiday',
            },
            {
              name: '全员年假配置',
              value: 'year',
            },
            {
              name: '工伤假配置',
              value: 'injury',
            },
            {
              name: '参加工作/入职日期配置',
              value: 'job',
            },
            {
              name: '考勤白名单',
              value: 'white-list',
            },
          ]"
          :key="item.value"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <div v-if="active === 'clock'">
        <Clock />
      </div>
      <div v-if="active === 'holiday'">
        <Holiday />
      </div>
      <div v-if="active === 'year'">
        <AllYear />
      </div>
      <div v-if="active === 'injury'">
        <Injury />
      </div>
      <div v-if="active === 'job'">
        <JobTime />
      </div>
      <div v-if="active === 'white-list'">
        <WhiteList />
      </div>
    </a-card>
  </div>
</template>
  
<script>
import Clock from "./components/clock.vue";
import Holiday from "./components/holiday-config.vue";
import AllYear from "./components/all-year.vue";
import Injury from "./components/injury.vue";
import JobTime from "./components/job-time.vue";
import WhiteList from "./components/white-list.vue";

export default {
  components: {
    Clock,
    Holiday,
    AllYear,
    Injury,
    JobTime,
    WhiteList
  },

  data() {
    return {
      active: "clock"
    };
  },

  methods: {}
};
</script>