var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false}},[_c('a-tabs',{attrs:{"tabBarStyle":{
        margin: '0',
      }},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l(([
             {
            name: '打卡时间配置',
            value: 'clock',
          },
          {
            name: '法定节假日配置',
            value: 'holiday',
          },
          {
            name: '全员年假配置',
            value: 'year',
          },
          {
            name: '工伤假配置',
            value: 'injury',
          },
          {
            name: '参加工作/入职日期配置',
            value: 'job',
          },
          {
            name: '考勤白名单',
            value: 'white-list',
          },
        ]),function(item){return _c('a-tab-pane',{key:item.value,attrs:{"tab":item.name}})}),1)],1),_c('a-card',{staticClass:"container"},[(_vm.active === 'clock')?_c('div',[_c('Clock')],1):_vm._e(),(_vm.active === 'holiday')?_c('div',[_c('Holiday')],1):_vm._e(),(_vm.active === 'year')?_c('div',[_c('AllYear')],1):_vm._e(),(_vm.active === 'injury')?_c('div',[_c('Injury')],1):_vm._e(),(_vm.active === 'job')?_c('div',[_c('JobTime')],1):_vm._e(),(_vm.active === 'white-list')?_c('div',[_c('WhiteList')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }