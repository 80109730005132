<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="18">
          <a-form-model-item>
            <a-input
              v-model="form.name"
              placeholder="姓名"
              style="width: 120px"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="6">
          <div class="right">
            <input
              ref="upload"
              style="display: none"
              type="file"
              :multiple="false"
              @change="onFileChange"
            />

            <a-space>
              <a href="/员工工作时间及入职时间模版.xlsx" target="_blank">
                <a-button>下载模版</a-button>
              </a>

              <a-button type="primary" :loading="uploading" @click="add"
                >导入入职时间</a-button
              >
            </a-space>
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table
      bordered
      :dataSource="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
      rowKey="id"
    >
      <a-table-column title="部门" data-index="dept"></a-table-column>
      <a-table-column title="姓名" data-index="name" align="center">
      </a-table-column>

      <a-table-column title="手机号" data-index="mobile" align="center" />
      <a-table-column
        title="参加工作时间"
        data-index="workStartTime"
        align="center"
      />
      <a-table-column title="入职时间" data-index="entryDate" align="center" />
    </a-table>
  </div>
</template>
  
<script>
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/user-service/talentMap/queryEmployeeDateList",
    method: "post",
    data,
  });
}

function upload({ data, type }) {
  return request({
    url: "/user-service/talentMap/importData?type=" + type,
    method: "post",
    data,
  });
}

export default {
  data() {
    return {
      form: {},
      loading: false,

      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      uploading: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },

    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    add() {
      this.$refs.upload.click();
    },

    onFileChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.uploading = true;

      upload({ data, type: "hr_entry_date" })
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            if (res.length > 0) {
              alert(res.filter((item, index) => index < 10).join("; "));
            } else {
              this.$message.success("导入成功");
              this.getList();
            }
          }
        })
        .finally(() => {
          this.uploading = false;
          e.target.value = null;
        });
    },
  },
};
</script>