<template>
  <div>
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-range-picker :allowClear="false" v-model="form.date" />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button type="primary" @click="add"> 添加 </a-button>
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <a-modal
      title="新增工伤假"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
      >
        <a-form-item label="日期范围">
          <a-range-picker :placeholder="['', '']" v-model="selectedDate" />
        </a-form-item>

        <a-form-item label="情况说明">
          <a-textarea v-model="remark" />
        </a-form-item>

        <a-form-item label="员工">
          <EmployeeSelector :single="true" @change="addUser">
            <a-button type="primary">
              {{ selectedUser.name ? selectedUser.name : "选择" }}
            </a-button>
          </EmployeeSelector>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" @click="save" :loading="saveLoading"
              >提交</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>

    <Padding />

    <a-table bordered :dataSource="list" :loading="loading" rowKey="id">
      <a-table-column title="部门" data-index="deptName"></a-table-column>

      <a-table-column title="姓名" data-index="name" align="center">
      </a-table-column>

      <a-table-column title="日期范围" align="center">
        <template slot-scope="text">
          <span> {{ text.startTime }} ~ {{ text.endTime }} </span>
        </template>
      </a-table-column>

      <a-table-column title="天数" data-index="duration" align="center">
      </a-table-column>

      <!-- <a-table-column title="状态" align="center">
        <template slot-scope="text">
          <span v-if="text.optType === 'add'">
            <a-tag color="green"> 已增加 </a-tag>
          </span>
          <span v-if="text.optType === 'cancel'">
            <a-tag color="red"> 已取消 </a-tag>
          </span>
        </template>
      </a-table-column> -->

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a href="#" class="danger" @click.prevent="edit(text)"> 取消 </a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
  
<script>
import request from "@/api/request";
import moment from "moment";
import EmployeeSelector from "@/components/employee-selector";

function fetchList(data) {
  return request({
    url: "/office-service/att/leave/list",
    method: "post",
    data,
  });
}

function addRequest(data) {
  return request({
    url: "/office-service/att/leave/admin_add",
    method: "post",
    data,
  });
}

export default {
  components: {
    EmployeeSelector,
  },

  data() {
    return {
      form: {
        date: [moment().subtract(1, "years"), moment()],
      },

      loading: false,
      list: [],

      visible: false,
      selectedUser: {},
      selectedDate: null,
      remark: "",

      saveLoading: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;

      let startTime, endTime;
      if (Array.isArray(this.form.date) && this.form.date.length === 2) {
        startTime = this.form.date[0].format("YYYY-MM-DD HH:mm:ss");
        endTime = this.form.date[1].format("YYYY-MM-DD HH:mm:ss");
      }

      fetchList({
        type: "work_related_injury", // 工伤假
        startTime,
        endTime,
      })
        .then((res) => {
          this.loading = false;
          if (res && Array.isArray(res)) {
            this.list = Object.freeze(
              res.filter((item) => item.optType !== "cancel")
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    query() {
      this.getList();
    },

    reset() {
      this.form = {
        date: [moment().subtract(1, "years"), moment()],
      };
      this.getList();
    },

    add() {
      this.visible = true;
    },
    cancel() {
      this.selectedDate = null;
      this.selectedUser = {};
      this.remark = "";
      this.visible = false;
    },

    addUser(list) {
      if (list.length > 0) {
        console.log("list", list[0]);
        this.selectedUser = list[0];
      }
    },

    save() {
      if (!this.selectedDate) {
        this.$message.error("请选择日期范围");
        return;
      }
      if (this.selectedDate && this.selectedDate.length !== 2) {
        this.$message.error("请选择正确的日期范围");
        return;
      }

      if (!this.selectedUser.name) {
        this.$message.error("请选择员工");
        return;
      }
      if (!this.remark) {
        this.$message.error("请输入情况说明");
        return;
      }

      // 计算工伤假的长度，不跳过周末和节假日
      const startDate = this.selectedDate[0];
      const endDate = this.selectedDate[1];

      let diff = endDate.diff(startDate, "days");
      let num = diff - 1 + 2; // 加上首尾两天
      console.log("num", num);
      this.saveLoading = true;
      addRequest({
        allUserFlag: false,
        isAdminOpt: true,
        optType: "add",
        duration: num,
        durationUnit: "day",

        startTime: startDate.format("YYYY-MM-DD") + " 09:00:00",
        endTime: endDate.format("YYYY-MM-DD") + " 17:30:00",
        type: "work_related_injury",
        userIdList: [this.selectedUser.userId],
        remark: this.remark,
      })
        .then(() => {
          this.getList();
          this.cancel();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    edit(text) {
      const that = this;
      this.$confirm({
        title: "确认要取消吗？",
        onOk() {
          const hide = that.$message.loading("取消中...");

          addRequest({
            allUserFlag: false,
            isAdminOpt: true,
            optType: "cancel",
            duration: text.duration,
            durationUnit: "day",

            startTime: text.startTime,
            endTime: text.endTime,
            type: "work_related_injury",
            userIdList: [text.userId],
            remark: text.remark,
            pid: text.id,
          })
            .then(() => {
              that.getList();
            })
            .finally(() => {
              hide();
            });
        },
      });
    },
  },
};
</script>