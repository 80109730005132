<template>
  <div style="padding: 12px">
    <a-row>
      <a-col :span="22">
        <a-form-model layout="inline">
          <a-form-model-item>
            <a-input v-model="name" placeholder="姓名" style="width: 120px" />
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="2">
        <div class="right" style="margin-top: 4px">
          <EmployeeSelector :single="true" @change="add">
            <a-button type="primary"> 添加 </a-button>
          </EmployeeSelector>
        </div>
      </a-col>
    </a-row>

    <Padding />

    <a-table bordered :dataSource="searchList" :loading="loading" rowKey="id">
      <a-table-column title="部门" data-index="deptName" />

      <a-table-column title="姓名" data-index="name" align="center">
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)"> 删除 </a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
  
<script>
import EmployeeSelector from "@/components/employee-selector";

import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/office-service/ding/attendance/whiteList/list",
    params,
  });
}

function edit(data) {
  return request({
    url: "/office-service/ding/attendance/whiteList/submit",
    method: "post",
    data,
  });
}

export default {
  components: {
    EmployeeSelector,
  },

  data() {
    return {
      name: "",

      loading: false,
      list: [],
    };
  },

  computed: {
    searchList() {
      return this.list.filter((item) => item.name.indexOf(this.name) > -1);
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      this.list = [];

      fetchList()
        .then((res) => {
          this.loading = false;
          if (res && Array.isArray(res)) {
            this.list = res;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    add(list) {
      if (list.length > 0) {
        console.log("list", list[0]);

        const user = list[0];

        this.save([
          ...this.list,
          {
            name: user.name,
            userId: user.userId,
            deptName: user.deptUniqueName,
            deptId: user.deptId,
          },
        ]);
      }
    },

    remove(index) {
      this.list.splice(index, 1);
      this.save(this.list);
    },

    save(list) {
      const hide = this.$message.loading("保存中...");
      edit(
        list.map((item) => {
          return {
            id: item.id,
            name: item.name,
            userId: item.userId,
            deptName: item.deptName,
            deptId: item.deptId,
          };
        })
      )
        .then(() => {
          this.getList();
        })
        .finally(() => {
          hide();
        });
    },
  },
};
</script>