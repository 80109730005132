


<template>
  <div style="padding-top: 16px">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="打卡日期">
            <a-date-picker
              show-time
              v-decorator="[
                  'date',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              style="width: 100%"
              placeholder
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="打卡类型">
            <a-radio-group
              v-decorator="[
                  'checkType',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
            >
              <a-radio
                v-for="(item, index) in [
                    {
                      name: '上班',
                      value: 'OnDuty',
                    },
                    {
                      name: '下班',
                      value: 'OffDuty',
                    },
                  ]"
                :key="index"
                :value="item.value"
              >
                <span>{{ item.name }}</span>
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="员工">
            <EmployeeSelector :single="true" @change="onSelectEmployee">
              <a-button type="primary">{{ selected.userId ? selected.name : '选择'}}</a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="footer center">
        <a-button htmlType="submit" type="primary" :loading="saveLoading">确认新增</a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";

import EmployeeSelector from "@/components/employee-selector";

import { addClock } from "@/api/human-resources/clock";

export default {
  mixins: [watermark],

  components: {
    EmployeeSelector
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selected: {},
      saveLoading: false
    };
  },

  methods: {
    onSelectEmployee(list) {
      if (list.length > 0) {
        this.selected = list[0];
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          if (!this.selected.userId) {
            this.$message.error("请选择员工");
            return;
          }
          this.saveLoading = true;

          const userCheckTime = values.date.format("YYYY-MM-DD HH:mm:ss");

          addClock([
            {
              ...values,

              userCheckTime,
              date: undefined,

              userId: this.selected.userId,
              name: this.selected.name,
              mobile: this.selected.mobile
            }
          ])
            .then(() => {
              this.selected = {};
              this.form.resetFields();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>


<style lang="less" scoped>
.container {
  padding-top: 24px;
}
.footer {
  padding: 80px 0;
}
</style>
